import {ReactElement} from "react";
import {Avatar, ListItemButton, ListItemAvatar, ListItemText, Stack, Typography} from "@mui/material";
import {DocumentType} from "../type/DocumentType";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export function DocumentItem(props: any): ReactElement {
    const itemPart: DocumentType = props.doc as DocumentType;

    const downloadFile = (id: bigint) => {
        //console.info('You clicked the Chip.');
    };

    return (
        <ListItemButton alignItems="flex-start" onClick={() => downloadFile(itemPart.id)}>
            <ListItemAvatar>
                <Avatar><FileDownloadIcon /></Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={itemPart.name}
                secondary={
                    <Stack direction="row" spacing={1}>
                        <Typography component="span" variant="body2" sx={{ color: 'text.secondary', display: 'inline' }}>{itemPart.uploadedAt}</Typography>
                        <Typography component="span" variant="body2" sx={{ color: 'text.primary', display: 'inline' }}>{itemPart.uploadedBy.fio}</Typography>
                    </Stack>
                }
            />
        </ListItemButton>
    )
}