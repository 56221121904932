import React, {ReactElement} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import IndexPage from "./page/IndexPage";
import {DocumentPage} from "./page/DocumentPage";

export function App(): ReactElement {
  return (<>
      <BrowserRouter>
          <Routes>
              <Route path={"/"} element={<IndexPage/>}/>
              <Route path={"/news"} element={<IndexPage/>}/>
              <Route path={"/docs"} element={<DocumentPage/>}/>

              <Route path={"*"} element={<Navigate to={"/"}/>}/>
          </Routes>
      </BrowserRouter>
      </>);
}
