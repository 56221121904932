import React, {ReactElement} from "react";
import {AppBar, Box, Button, Container, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";

export function MenuBlock(props: any): ReactElement {
    return (
        <AppBar>
            <Container>
                <Toolbar>
                    <Box>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2, display: {md: 'none'}}}
                        >
                            <MenuIcon/>
                        </IconButton>
                    </Box>


                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Button key={'index'} sx={{my: 2, color: 'white', display: 'block', textTransform: 'none'}}
                                href={'/'}>
                            <Typography variant="subtitle1">{process.env.REACT_APP_TITLE}</Typography>
                        </Button></Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Button key={'news'} sx={{my: 2, color: 'white', display: 'block'}}
                                href={'/news'}>Новости</Button>
                        <Button key={'docs'} sx={{my: 2, color: 'white', display: 'block'}}
                                href={'/docs'}>Документы</Button>
                        <Button key={'requests'} sx={{my: 2, color: 'white', display: 'block'}}
                                href={'/requests'}>Обращения</Button>
                    </Box>

                    <Box>
                        <Button color={"inherit"} href={"/login"}><LoginIcon sx={{mr: 1}}/>Войти</Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}