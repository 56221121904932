import {NewsType} from "./type/NewsType";
import {DocumentType} from "./type/DocumentType";

export class REST {
    public static BASE: String = process.env.REACT_APP_BASE ?? "";

    public static GET<T>(uri: string): Promise<T> {
        return fetch(this.BASE + uri, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            }
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/login';
                }
                return response.json();
            })
            .then((data: Promise<T>) => {
                return data;
            });
    }

    public static getNews(page: number, pageSize: number): Promise<NewsType[]> {
        return REST.GET("/api/news?page=" + page + "&size=" + pageSize);
    }

    public static getDocuments(page: number, pageSize: number): Promise<DocumentType[]> {
        return REST.GET("/api/doc?page=" + page + "&size=" + pageSize);
    }
}