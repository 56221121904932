import React, {ReactElement, useEffect, useState} from "react";
import {Box, CircularProgress, Container, Grid2, List, ListSubheader, Toolbar, Typography} from "@mui/material";
import {MenuBlock} from "../component/MenuBlock";
import InfiniteScroll from "react-infinite-scroll-component";
import {DocumentType} from "../type/DocumentType";
import {REST} from "../REST";
import {DocumentItem} from "../component/DocumentItem";

export function DocumentPage(props: any): ReactElement {
    const pageSize: number = 10;
    const [items, setItems] = useState<DocumentType[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);

    useEffect(() => {
        if (currentPage === 0) {
            REST.getDocuments(currentPage, pageSize).then(dd => {
                if (dd.length < pageSize) {
                    setHasMore(false);
                }
                setItems(dd);
            });
        }
    }, [currentPage]);

    const fetchMore = () => {
        REST.getDocuments(currentPage, pageSize).then(dd => {
            setCurrentPage(prevPage => prevPage + 1);
            setItems(prevItems => [...prevItems, ...dd]);
        });
    };

    return (<>
        <Grid2 spacing={0} sx={{minHeight: '100vh'}}>

            <MenuBlock/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <List sx={{bgcolor: 'background.paper'}} subheader={<ListSubheader component="div">Документы</ListSubheader>}>
                    <InfiniteScroll
                        dataLength={items.length}
                        next={fetchMore}
                        hasMore={hasMore}
                        loader={<Box
                            sx={{width: '100%', textAlign: 'center', overflow: 'hidden', mt: 1}}><CircularProgress
                            color="inherit"/></Box>}
                        endMessage={items.length > 0 ? '' :
                            <Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden', mt: 1}}>
                                <Typography>Документов нет</Typography></Box>}
                    >
                        {items.map((item, index) => (
                            <DocumentItem key={item.id} doc={item}/>
                        ))}
                    </InfiniteScroll>
                </List>
            </Container>

        </Grid2>
    </>)
}
