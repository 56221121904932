import {ReactElement} from "react";
import {
    Stack,
    Card,
    Avatar,
    CardContent,
    Typography,
    CardActions,
    Chip, Divider
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { NewsType } from "../type/NewsType";
import { REST } from "../REST";

export function NewsItem(props: any): ReactElement {
    const itemPart: NewsType = props.news as NewsType;

    const handleLike = () => {
        //console.info('You clicked the Chip.');
    };

    return (
        <Card sx={{mt: 1, mb: 2}} variant="outlined">
            <CardContent>
                <Typography variant="h6" align={'center'} gutterBottom>{itemPart.title}</Typography>
                <Typography variant="body2" sx={{color: 'text.secondary'}} align={'center'} dangerouslySetInnerHTML={{__html: itemPart.content}}/>
            </CardContent>
            <Divider/>
            <CardActions disableSpacing>
                <Chip icon={<FavoriteIcon color={itemPart.liked ? 'error' : undefined}/>} label={itemPart.countLike} sx={{mr: 1}} onClick={handleLike}/>

                <Stack direction="row" sx={{ml: 'auto'}} spacing={1}>
                    <Avatar src={REST.BASE + '/api/user/' + itemPart.author.id + '/avatar'}/>
                    <Stack>
                        <Typography variant={'body2'}>{itemPart.author.fio}</Typography>
                        <Typography variant={'caption'}>{itemPart.publishDate}</Typography>
                    </Stack>
                </Stack>
            </CardActions>
        </Card>
    );
}